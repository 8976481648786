<template>
  <b-container>
    <b-row align-h="center">
      <b-col cols="6">
        <b-card>
          <b-form v-on:submit.prevent>
            <b-row>
              <b-col>
                <b-form-group label="Organization" label-for="organization">
                  <v-select
                    id="organization"
                    v-model="form.selectedOrganization"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="form.optionsOrganization"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.selectedOrganization"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                  <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Name" label-for="name">
                  <b-form-input
                    id="name"
                    placeholder="Name"
                    v-model="form.name"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Address" label-for="address">
                  <b-form-textarea
                    id="address"
                    placeholder="Address"
                    v-model="form.address"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Village" label-for="village">
                  <b-form-input
                    id="village"
                    placeholder="Village"
                    v-model="form.village"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="District" label-for="district">
                  <b-form-input
                    id="district"
                    placeholder="District"
                    v-model="form.district"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="City" label-for="city">
                  <b-form-input
                    id="city"
                    placeholder="City"
                    v-model="form.city"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Province" label-for="province">
                  <b-form-input
                    id="province"
                    placeholder="Province"
                    v-model="form.province"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Country" label-for="country">
                  <b-form-input
                    id="country"
                    placeholder="Country"
                    v-model="form.country"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Postal Code" label-for="postal_code">
                  <b-form-input
                    id="postal_code"
                    placeholder="Postal Code"
                    v-model="form.postal_code"
                    type="number"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr/>
            <b-row>
              <b-col cols="6">
                <b-form-group label="PIC" label-for="pic">
                  <v-select
                    id="pic"
                    v-model="form.selectedPIC"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="form.optionsPIC"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.selectedPIC"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                  <!-- Selected: <strong>{{ form.selectedPIC }}</strong> -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone" label-for="phone">
                  <b-form-input
                    id="phone"
                    placeholder="Phone"
                    v-model="form.phone"
                    type="number"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="permissions.includes('warehouse-update')">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mt-1"
                  @click="validation()"
                  block
                >
                  Update
                </b-button>
              </b-col>
              <b-col v-if="permissions.includes('warehouse-delete')">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  class="mt-1"
                  @click="deleteWarehouseLocation()"
                  block
                >
                  Delete
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    ToastificationContent,
    vSelect,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        name: "",
        selectedPIC: null,
        optionsPIC: [],
        phone: null,
        address: "",
        village: "",
        district: "",
        city: "",
        province: "",
        country: "",
        postal_code: null,
        selectedOrganization: null,
        optionsOrganization: [],
      },
      permissions: [],
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getAllPermissions();
    this.getOrganization();
    this.getEmployee();
    this.getDetails();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getOrganization() {
      this.$http.get("organizations/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsOrganization.push({
          value: null,
          text: "Select 1 Organization",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          // console.log($data)
          this.form.optionsOrganization.push($data);
        }
        // console.log(this.form.optionsOrganization)
      });
    },
    getEmployee() {
      this.$http.get("employees").then((response) => {
        // console.log(response.data.data)
        this.form.optionsPIC.push({ value: null, text: "Select 1 Employee" });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          // console.log($data)
          this.form.optionsPIC.push($data);
        }
        // console.log(this.form.optionsPIC)
      });
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http.get("warehouse/" + id).then((response) => {
        // console.log(response.data.data)
        this.form.name = response.data.data.name;
        this.form.selectedOrganization = {
          value: response.data.data.organization_id,
          text: response.data.data.organization_name,
        };
        this.form.selectedPIC = {
          value: response.data.data.pic,
          text: response.data.data.pic_name,
        };
        this.form.phone = response.data.data.phone;
        this.form.address = response.data.data.address;
        this.form.village = response.data.data.village;
        this.form.district = response.data.data.district;
        this.form.city = response.data.data.city;
        this.form.province = response.data.data.province;
        this.form.country = response.data.data.country;
        this.form.postal_code = response.data.data.postal_code;
      });
    },
    validation() {
      if (this.form.name === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Name field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedOrganization === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Organization field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedPIC === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The PIC field is required",
            variant: "warning",
          },
        });
      } else if (this.form.phone === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Phone field is required",
            variant: "warning",
          },
        });
      } else if (this.form.address === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Address field is required",
            variant: "warning",
          },
        });
      } else if (this.form.village === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Village field is required",
            variant: "warning",
          },
        });
      } else if (this.form.district === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The District field is required",
            variant: "warning",
          },
        });
      } else if (this.form.city === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The City field is required",
            variant: "warning",
          },
        });
      } else if (this.form.province === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Province field is required",
            variant: "warning",
          },
        });
      } else if (this.form.country === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Country field is required",
            variant: "warning",
          },
        });
      } else if (this.form.postal_code === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Postal Code field is required",
            variant: "warning",
          },
        });
      } else {
        this.updateWarehouseLocation();
      }
    },
    updateWarehouseLocation() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/" + id + "/update", {
          name: this.form.name,
          organization_id: this.form.selectedOrganization.value,
          pic: this.form.selectedPIC.value,
          phone: this.form.phone,
          address: this.form.address,
          village: this.form.village,
          district: this.form.district,
          city: this.form.city,
          province: this.form.province,
          country: this.form.country,
          postal_code: this.form.postal_code,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success update Warehouse",
              variant: "success",
            },
          });
          location.href = "/warehouse/warehouse-location";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteWarehouseLocation() {
      const id = this.$route.params.id;
      this.$http
        .post("warehouse/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Warehouse Location has been Deleted",
              variant: "danger",
            },
          });
          location.href = "/warehouse/warehouse-location";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>